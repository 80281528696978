 /* Global css */
:root{ 
  --background-color: #e3e6f3;
  --text-color: #666;
  --primary-color: #09513e;
}

*{
    box-sizing: border-box;
    /* color: var(--text-color); */
}

body, html{
    margin: 0;
    padding: 0;
    max-width: 1900px;
    margin: auto;
}

a{
    text-decoration: none;
    color: black;
}

li{
    list-style: none;
}

.btn {
    border: none;
    background-color: var(--primary-color);
    color: white;
    padding: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}
  
.btn:hover{
    background-color: #0d765a;
}

section{
    padding: 1.5rem;
    max-width: 1900px;
}
@media only screen and (max-width: 500px){
    section{
        padding: 1.5rem .5rem;
    }
}
.title{
    text-align: center;
    margin-bottom: 1.5rem;
}
.title h2{
    font-size: 2.5rem;
    margin: 0;
}
.title .underline{
    width: 200px;
    height: 3px;
    background-color: var(--primary-color);
    margin: auto;
}

/* navbar */
.navbar{
    height: 60px;
    background-color: var(--background-color);
    max-width: 1900px;
}
.nav-center{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 8rem;
}

.nav-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.toggle-sidebar{
    display: none;
}

.nav-links{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-links li{
    padding-right: 3rem;
    font-weight: bold;
    font-size: 1.1rem;
    position: relative;
}

.nav-links a:hover{
    color: var(--primary-color);
}
.active a{
    color: var(--primary-color);
}

.nav-links li:hover a::after,
.nav-links li.active a::after{
    display: block;
    content: "";
    width: 40%;
    height: 2px;
    background-color: var(--primary-color);
    position: relative;
    top: 1px;
    left: 0;
  }

.cart-value{
    background-color: var(--primary-color);
    color: white;
    font-size: 12px;
    padding: .15rem .3rem;
    border-radius: 50%;
    position: absolute; 
    top: -10px;
    left: 10px ;
}

/* navbar media query */
@media (max-width: 900px) {
    .nav-center{
        margin: 0 3rem;
    }
    .nav-links{
        display: none;
    }
    .toggle-sidebar{
        background-color: transparent;
        border: none;
        font-size: 1.5rem;
        display: block;
        cursor: pointer;
    }
}

/* sidebar */
.sidebar.show{
    transform: translateX(0%);
}
.sidebar{
    width: 100%;
    height: 100vh;
    padding: 5px 3rem;
    background-color: var(--background-color);
    padding-top: 1rem;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    transition: transform 300ms;
    z-index: 1;
}

.sidebar-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-links{
    margin: 0;
    padding: 0;
    margin-top: 1rem;
}

.sidebar-links li{
    display: block;
    margin: 0;
    padding: 1rem;
    font-weight: bold;
    font-size: 1.3rem;
    position: relative;
    transition: padding-left 100ms;
}

.sidebar-links li:hover{
    background-color: #d2d5e2;
    padding-left: 1.5rem;
}

.sidebar-links .cart-value{
    top: -20px;
    left:-10px ;
    position: relative;
}

/* hero */

.hero {
    background: url(images/hero4.png) 70% 30% no-repeat;
    height: 110vh;
    background-color: var(--background-color);
}

.hero .container {
    padding-top: 5%;
    padding-left: 20%;
    margin: 0;
}

.hero .display-3{
    font-size: 3rem;
    font-weight: lighter;
}
.hero p{
    opacity: .5;
}

.hero .visual {
    color: var(--primary-color);
}

.hero button {
    background: url(images/button.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
    font-size: 1.6rem;
    padding: 1rem 2rem;
    color: var(--primary-color);
    font-weight: bold;
    transition: transform 200ms;
}
.hero button:hover {
    border: none;
    background-color: var(--background-color);
    transform: scale(1.1);
}

/* feature */

.features .container{
    display: grid;
    grid-template-columns:  repeat(auto-fit, minmax(220px, 1fr));
    gap: 1rem;
}

.feature{
    height: 250px;
    box-shadow: 0 0 10px 0 #666;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
}
.feature img{
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.feature h5{
    text-align: center;
    font-size: 1.2rem;
    margin: 0;
}

/* Products */
.products{
    background-color: var(--background-color);
    width: 100%;
}
.products .more{
    display: block;
    margin: 2rem auto;
}
.products-container{
    display: grid;
    margin-top: 3rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
}

.product-card{
    width: 300px;
    margin: auto;
    border-radius: 20px;
    background-color: var(--background-color);
    border: 1px solid rgba(0, 0, 0, 0.2);
    transition: box-shadow 300ms;
}
.product-card:hover{
    box-shadow: 0 0 1rem 0 var(--text-color);
}

.product-card .head{
    width: 90%;
    height: 300px;
    margin: auto;
    margin-top: 10px ;
}
.product-card img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: white;
    border-radius: 20px;
}

.product-card .footer{
    padding: 0 10%;
}
.product-card .footer h3{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 100ms;
}
.product-card .footer h3:hover{
    color: orange;
}

.rating span:first-child{
    color: goldenrod;
}

.product-card .footer .price{
    color: var(--primary-color);
    font-size: 2rem;
    margin: 0;
}


/* newsletter */
.newsletter{
    background-color: rgb(0, 0, 50);
    color: white;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.newsletter span{
    color: orange;
}
.newsletter div{
    flex: 2;
    min-width: 300px;
    text-align: center;
}
.newsletter form{
    flex: 3;
    align-self: center;
    height: 50px;
    max-width: 700px;
    min-width: 450px;
    margin: auto;
    display: flex;
    align-items: center;
}
.newsletter form input{
    width: 80%;
    height: 100%;
    border-radius:  5px 0 0 5px ;
    border: none;
    padding: 0 1rem;

}
.newsletter form button{
    width: 20%;
    height: 100%;
    border-radius: 0 5px 5px 0;
}
.newsletter form button:hover{
    background-color: #0d765a;
    color: white;
}
@media only screen and (max-width: 500px){
    .newsletter form{
       min-width: 300px;
    }
    .newsletter form button{
        font-size: 10px;
    }
}

/* footer */
footer{
    padding: 1rem;
    text-align: center;
    background-color: #111;
    color: var(--text-color);
}

footer a{
    color: inherit;
}
footer a:hover{
    color: var(--primary-color);
}

/* about */
.about{
    width: 100%;
    height: 74vh;
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
}
.about .image{
    width: 50%;
    height: 100%;
}
.about .image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about .title{
    width: 50%;
}
.about .title p{
    text-align: start;    
}

@media (max-width: 900px){
    .about{
        flex-direction: column;
        height: auto;
    }
    .about .image{
        width: 100%;
    }
    .about .title{
        width: 100%;
    }
}

/* filter */
.filter{
    background-color: var(--background-color);
    width: 210px;
    margin: 4rem auto;
}
.filter input[type='radio']{
    display: none;
}
.filter fieldset{
    border: none;
    margin: 0;
    margin-top: 2rem;
    padding: 0;
}
.filter input[type='text']{
    border: none;
    padding: 0.5rem;
    border-radius: 5px;
}
.filter h3{
    margin: 0;
}
.filter input[type='radio']:checked + label{
    text-decoration: underline;
    opacity: 1;
}
.filter label{
    opacity: .5;
    display: block;
    cursor: pointer;
}

.filter fieldset button{
    background-color: darkred;
}
.filter button:hover{
    background-color: rgb(194, 0, 0);
    color: white;
    border: none;
}
/* .filter .close{
    background-color: transparent;
    border: none;
    font-size: 2rem;
    margin: auto;
    color: darkred;
    text-align: end;
} */
/* shop */
.shop{
    display: flex;
    position: relative;
    background-color: var(--background-color);
}
@media (max-width: 900px) {
    .shop{
        flex-wrap: wrap;
    }
}
/* error Page */
.error-page{
    height: 74vh;
    padding-top: 6rem;
}
.error-page button{
    margin-top: 2rem;
}
 
/* cart page */

.cart div:first-child{
    overflow-y: auto;
    max-width: 1100px;
    margin: auto;
}
.cart-resume{
    width: 100%;
    text-align: center;
    min-width: 700px;
    overflow-y: auto; 
    border-collapse: collapse;
    table-layout: fixed;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.cart-resume div{
    margin: 1rem 0;
}

.cart-resume .item{
    width: 40%;
}

.cart-resume .item div{
   display: flex;
}
.cart-resume .item img{
    width: 50%;
    height: 100%;
    object-fit: cover;
    flex: 1;
}
.cart-resume .item p{
    flex: 1;
    text-align: start;
    padding-left: 5px;
    align-self: center;
}

.cart-resume th{
    padding-bottom: 1rem;
    opacity: .7;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.cart-resume td{
    padding-top: 1rem;
}


.cart-resume .price{
    font-size: 1.2rem;
    color: orange;
}

.cart-resume .quantity div{
    display: flex;
    justify-content: center;
    font-size: 2rem;
    font-weight: bold;
}
.cart-resume .quantity button{
    background-color: transparent;
    border: none;
    flex: 1;
    cursor: pointer;
}

.cart-resume .subtotal{
    font-size: 1.5rem;
    color: var(--primary-color);
}
.cart-resume .remove{
    width: 3%;
    color: darkred;
    cursor: pointer;
}

.cart .action{
    max-width: 1100px;
    margin: 1rem auto;
    display: flex;
    justify-content: space-between;
}
.cart .action .clear-cart{
    background-color: darkred;
}
.cart .action .clear-cart:hover{
    background-color: red;
}

.total{
    width: 400px;
    padding: 1rem;
    margin: auto;
}
.total table{
    width: 100%;
    border-collapse: collapse;
    border: 1px solid rgba(0, 0, 0, 0.2) ;
    text-align: start;
    margin-bottom: 1rem;
}
.total table tbody tr:first-child{
    font-weight: bold;
}

.total table td{
    padding: .5rem 0;
    padding-left: 2rem;
}
.total table th{
    padding: 2rem;
    font-size: 1.4rem;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    text-align: start;
}
.total .login-btn{
    display: block;
    width: 100%;
}
@media (max-width: 450px) {
    .total{
        width: 300px;
    }
    .cart-resume .item{
        width: 30%;
    }
}

 /* single product page */
.product-container{
    width: 100%;
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px,1fr));
    gap: 2rem;
}

.product-images{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 70vh;
}
.product-images .main-image{
    height: 80%;
    width: 100%;
    object-fit: contain;
}
.product-images .galerie{
    padding-top: 1rem;
    display: flex;
    justify-content: space-around;
    height: 100px;
}
.product-images .galerie img{
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 18%;
    height: 80px;
    object-fit: contain;
}
.product-container img{
    border-radius: 5px;
}

.actions div{
    display: flex;
    justify-content: start;
    font-size: 2rem;
    font-weight: bold;
}
.actions div button{
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.product-content h3{
    font-size: 1.5rem;
    margin: 0;
}

.bold{
    font-weight: bold;
}

.product-content hr{
    margin: 2rem 0;
}

 /* single product page  media*/
@media only screen and (max-width: 650px){

    .product-container{
        grid-template-columns: none;
    }
    .product-images{
        height: 50vh;
    }
}



